import { useQuery } from "@apollo/client";
import Box from "@mui/material/Box";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { graphql } from "../../../api/__generated__";
import { ConsultationTypes } from "../../../data-model/types/consultation/Consultation";
import Professional from "../../../data-model/types/professional/Professional";
import { IFormState } from "../../hooks/useFormState";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import Button from "../../ui/buttons/Button";
import { ISelectObject } from "../../ui/form/select/BaseSimpleSelect";
import SingleSelect from "../../ui/form/select/SingleSelect";
import Label from "../../ui/text/Label";
import TimeSlotBoxes from "./TimeSlotBoxes";
import TimeSlot from "../../../data-model/types/consultation/TimeSlot";
import { IRequestConsultationContext } from "./context/RequestConsultation";
import RequestConsultationContext from "./context/RequestConsultationContext";
import { IRequestConsultationFormStateValues } from "./useRequestConsultationFormState";
import CostSentence from "../match/ChooseProf/CostSentence";

const userQuery = graphql(`
  query RequestConsultationFormUser {
    currentUser {
      human {
        ...CostSentenceHuman
        id
        mutuality {
          id
          title
        }
        organization {
          coachingEnabled
          ...CostSentenceOrganization
          id
        }
      }
      id
    }
  }
`);

const profQuery = graphql(`
  query RequestConsultationFormProf($professionalId: Int) {
    professional(id: $professionalId) {
      ...CostSentenceProfessional
      id
    }
  }
`);

interface RequestConsultationFormProps {
  formState: IFormState<IRequestConsultationFormStateValues>;
  onSubmit: () => void;
  professionals: Array<Professional>;
}

const RequestConsultationForm = ({
  professionals,
  formState,
  onSubmit,
}: RequestConsultationFormProps) => {
  const { t: translate, i18n } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Human,
  ]);

  const { submitting } = useContext<IRequestConsultationContext>(
    RequestConsultationContext,
  );

  const [disabled, setDisabled] = useState(true);

  const [selectedProfessional, setSelectedProfessional] =
    useState<Professional | null>(null);

  const { data: humandata } = useQuery(userQuery);

  const { data: profData } = useQuery(profQuery, {
    variables: { professionalId: selectedProfessional?.getID() },
  });

  useEffect(
    () => setDisabled(formState.testForErrors()),
    [formState, formState.testForErrors],
  );

  const professionalOptions: Array<ISelectObject<Professional>> = useMemo(
    () =>
      professionals.map((c) => ({
        label: c.getScreenName(),
        value: c,
      })),
    [professionals],
  );

  const timeSlotOptions: Array<ISelectObject<TimeSlot>> = useMemo(() => {
    if (!selectedProfessional) return [];

    return selectedProfessional.getAvailability().map((ts) => ({
      label: ts.getStartTime().locale(i18n.language).format("dd DD MMM HH:mm"),
      value: ts,
    }));
  }, [i18n.language, selectedProfessional]);

  return (
    <>
      <Box mb={4} mt={2}>
        <Box mb={3} py={1}>
          <Label
            value={translate("human:request.consultation.select.type.label")}
          />
          <Box mt={1}>
            <SingleSelect<ConsultationTypes>
              options={[
                {
                  label: translate(
                    "common:consultation_type." + ConsultationTypes.APPOINTMENT,
                  ),
                  value: ConsultationTypes.APPOINTMENT,
                },
                ...(humandata?.currentUser?.human?.organization?.coachingEnabled
                  ? [
                      {
                        label: translate(
                          "common:consultation_type." +
                            ConsultationTypes.COACHING,
                        ),
                        value: ConsultationTypes.COACHING,
                      },
                    ]
                  : []),
                {
                  label: translate(
                    "common:consultation_type." + ConsultationTypes.INTRO,
                  ),
                  value: ConsultationTypes.INTRO,
                },
              ]}
              {...formState.getInputProps("type")}
              placeholder={translate(
                "human:request.consultation.select.type.placeholder",
              )}
            />
          </Box>
        </Box>
        <Box mb={3} py={1}>
          <Label value={translate("common:professional")} />
          <Box mt={1}>
            <SingleSelect<Professional>
              options={professionalOptions}
              {...formState.getInputProps("professional")}
              onSelected={(option) => {
                const found = professionals.find(
                  (c) => option.value.getID() === c.getID(),
                );

                if (found) setSelectedProfessional(found);
              }}
              placeholder={translate("human:consultations.select.user")}
            />
          </Box>
        </Box>
        {selectedProfessional && (
          <Box mb={3} py={1}>
            <Label
              value={translate("human:request.consultation.timeslot.label")}
            />
            <Box mt={1}>
              <TimeSlotBoxes
                label={translate(
                  "human:request.consultation.timeslot.placeholder",
                )}
                onSelectTime={(timeSlot) =>
                  formState.setValue("timeslot", timeSlot)
                }
                timeslotItems={timeSlotOptions}
              />
            </Box>
          </Box>
        )}
        {profData?.professional &&
          selectedProfessional &&
          humandata?.currentUser?.human && (
            <Box mb={3} py={1}>
              <Label value={translate("common:rate")} />
              <CostSentence
                appointmentType={
                  formState.getValue("type").value === "COACHING"
                    ? "COACHING"
                    : "THERAPY"
                }
                human={humandata.currentUser.human}
                mutualityName={
                  humandata.currentUser.human.mutuality?.title ?? undefined
                }
                organization={humandata.currentUser.human.organization}
                professional={profData.professional}
              />
              <Box mb={0} py={2}>
                <Label
                  value={translate(
                    "human:request.consultation.quick.response.label",
                  )}
                />
              </Box>
            </Box>
          )}
      </Box>
      <Box textAlign="left">
        <Button
          disabled={disabled}
          label={translate("common:book")}
          loading={submitting}
          onClick={onSubmit}
        />
      </Box>
    </>
  );
};

export default RequestConsultationForm;

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import "moment-timezone";
import { useTranslation } from "react-i18next";
import ConsultationBooker from "../../../../pages/professional-call/components/ConsultationBooker";
import Consultation from "../../../../../data-model/types/consultation/Consultation";
import SidePane from "./SidePane";

interface StandardConsultationBookerSidePaneProps {
  consultation: Consultation;
}

const StandardConsultationBookerSidePane = ({
  consultation,
}: StandardConsultationBookerSidePaneProps) => {
  const { t } = useTranslation();

  return (
    <SidePane
      TitleIcon={MoreHorizIcon}
      title={t("ui:sidepane.menu.book.consultation.title")}
    >
      <ConsultationBooker consultationId={consultation.getUUID()} />
    </SidePane>
  );
};

export default StandardConsultationBookerSidePane;

import {
  Box,
  Checkbox,
  FormControlLabel,
  styled,
  SxProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import Button from "../../../ui/buttons/Button";
import { graphql } from "../../../../api/__generated__";
import { useMutation } from "@apollo/client";
import { useContext } from "react";
import AuthContext from "../../../providers/auth/AuthContext";
import { prepareOneLanguageForAPI } from "../../../language/languagesUtil";
import ToastContext from "../../../providers/toast/ToastContext";
import StepMainContent from "../Common/StepMainContent";
import StepHeader from "../Common/StepHeader";
import Footer from "../Common/Footer";
import { InfoOutlined, Login, PersonAddAlt1 } from "@mui/icons-material";
import Email from "./Email.svg";
import LockOpen from "./LockOpen.svg";
import StyledInput from "./StyledInput";

const registerMutation = graphql(`
  mutation RegisterInMatching($input: RegisterHumanInput!) {
    registerHumanV2(input: $input) {
      accessToken
      refreshToken
    }
  }
`);

const StyledExtraInfo = styled("div")(() => ({
  "@media screen and (max-width: 800px)": {
    display: "none",
  },
  borderLeft: "1px solid #7BDBFF",
  color: "#797272",
  flex: "1 1 0px",
  paddingLeft: "24px",
}));

const LabelWithToolTip = ({
  labelText,
  name,
  sx,
  tooltipText,
}: {
  labelText: string;
  name?: string;
  sx?: SxProps;
  tooltipText: string;
}) => (
  <Box sx={{ alignItems: "center", display: "flex", fontSize: "20px", ...sx }}>
    {name ? (
      <label htmlFor={name}>{labelText}</label>
    ) : (
      <Typography>{labelText}</Typography>
    )}
    <Tooltip
      placement="bottom-start"
      slotProps={{
        tooltip: { sx: { fontSize: "14px" } },
      }}
      title={tooltipText}
    >
      <InfoOutlined
        sx={{
          "@media screen and (max-width: 800px)": {
            display: "inline",
          },
          display: "none",
          marginLeft: "4px",
        }}
      />
    </Tooltip>
  </Box>
);

const RegisterStep = ({
  bookIntroConsult,
  stepBack,
  stepForward,
}: {
  bookIntroConsult: () => Promise<void>;
  stepBack: () => void;
  stepForward: (nextStep: string) => void;
}) => {
  const { t, i18n } = useTranslation();
  const [register] = useMutation(registerMutation);
  const { internalAppLogin } = useContext(AuthContext);
  const { setToast, close: closeToast } = useContext(ToastContext);

  return (
    <>
      <StepHeader
        imageUrl={
          "https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D85-registration%2C+Size%3DSmall.png"
        }
        subtitle={t("match:registerStep.header.subtitle")}
        title={t("match:registerStep.header.title")}
      />
      <StepMainContent
        nextButtonIcon={<Login />}
        nextButtonLabel={t("match:registerStep.loginButton")}
        onClickNext={() => stepForward("login")}
        onClickPrevious={stepBack}
      >
        <Box
          sx={{
            "@media screen and (max-width: 800px)": {
              marginBottom: "48px",
            },
            marginBottom: "96px",
            maxWidth: "1000px",
          }}
        >
          <Formik
            initialValues={{
              contactForFeedback: false,
              email: "",
              password: "",
            }}
            onSubmit={async (values) => {
              closeToast();

              const { data, errors } = await register({
                variables: {
                  input: {
                    contactForFeedback: values.contactForFeedback,
                    email: values.email,
                    organizationCode: localStorage.getItem("orgCode"),
                    password: values.password,
                    preferredLanguage: prepareOneLanguageForAPI(i18n.language),
                  },
                },
              });

              if (
                errors?.[0].message ===
                "api_errors:human.registration.email_in_use"
              ) {
                setToast({
                  message: t("api_errors:human.registration.email_in_use"),
                  noHide: true,
                  severity: "warning",
                });

                return;
              } else if (
                errors?.[0].message === "api_errors:human.registration.failed"
              ) {
                setToast({
                  message: t("api_errors:human.registration.failed"),
                  noHide: true,
                  severity: "warning",
                });

                return;
              }

              if (
                !data?.registerHumanV2.accessToken ||
                !data.registerHumanV2.refreshToken
              ) {
                setToast({
                  message: "ERROR",
                  noHide: true,
                  severity: "warning",
                });

                return;
              }

              await internalAppLogin(
                data.registerHumanV2.accessToken,
                data.registerHumanV2.refreshToken,
              );

              await bookIntroConsult();
              stepForward("endMatching");
            }}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .required(t("validation:email.mandatory"))
                .email(t("validation:email.validity")),
              password: Yup.string()
                .required(t("validation:password.mandatory"))
                .min(16, t("validation:password.strength")),
            })}
          >
            {({
              handleSubmit,
              errors,
              handleBlur,
              values,
              handleChange,
              touched,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ flex: "1 1 0px", paddingRight: "24px" }}>
                      <LabelWithToolTip
                        labelText={t("common:email")}
                        name={"email"}
                        tooltipText={t("match:registerStep.email.extraInfo")}
                      />
                      <StyledInput
                        autoComplete="email"
                        id="email"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        sx={{
                          backgroundImage: `url('${Email}')`,
                          marginTop: "10px",
                        }}
                        type="email"
                        value={values.email}
                      />
                      {touched.email && errors.email && (
                        <Typography
                          sx={(theme) => ({
                            color: theme.palette.error.main,
                            fontSize: "14px",
                          })}
                          variant={"caption"}
                        >
                          {errors.email}
                        </Typography>
                      )}
                    </Box>
                    <StyledExtraInfo
                      sx={{
                        marginTop: "30px",
                      }}
                    >
                      <Typography>
                        {t("match:registerStep.email.extraInfo")}
                      </Typography>
                    </StyledExtraInfo>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ flex: "1 1 0px", paddingRight: "24px" }}>
                      <LabelWithToolTip
                        labelText={t("common:password")}
                        name={"password"}
                        sx={{ marginTop: "30px" }}
                        tooltipText={t("match:registerStep.password.extraInfo")}
                      />
                      <StyledInput
                        autoComplete="password"
                        id="password"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        sx={{
                          backgroundImage: `url('${LockOpen}')`,
                          marginTop: "10px",
                        }}
                        type="password"
                        value={values.password}
                      />
                      {touched.password && errors.password && (
                        <Typography
                          sx={(theme) => ({
                            color: theme.palette.error.main,
                            fontSize: "14px",
                          })}
                          variant={"caption"}
                        >
                          {errors.password}
                        </Typography>
                      )}
                    </Box>
                    <StyledExtraInfo
                      sx={{
                        paddingTop: "60px",
                      }}
                    >
                      <Typography>
                        {t("match:registerStep.password.extraInfo")}
                      </Typography>
                    </StyledExtraInfo>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ flex: "1 1 0px", paddingRight: "24px" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="contactForFeedback"
                            onChange={handleChange}
                            size="large"
                            value={values.contactForFeedback}
                          />
                        }
                        label={
                          <LabelWithToolTip
                            labelText={t("match:registerStep.feedback.label")}
                            sx={{ fontSize: "16px" }}
                            tooltipText={t(
                              "match:registerStep.feedback.extraInfo",
                            )}
                          />
                        }
                        sx={{
                          marginTop:
                            "24px" /* already a lot of padding around checkbox */,
                        }}
                      />
                    </Box>
                    <StyledExtraInfo
                      sx={{
                        paddingTop: "30px",
                      }}
                    >
                      <Typography>
                        {t("match:registerStep.feedback.extraInfo")}
                      </Typography>
                    </StyledExtraInfo>
                  </Box>
                  <Box
                    sx={{
                      "@media screen and (max-width: 800px)": {
                        marginTop: "32px",
                      },
                      marginTop: "96px",
                      textAlign: "center",
                    }}
                  >
                    <Button
                      bloomColor="bloom"
                      fullyColored
                      icon={<PersonAddAlt1 />}
                      label={t("common:register")}
                      style={{
                        borderRadius: "50px",
                        fontSize: "20px",
                        fontWeight: "bold",
                        height: "unset",
                        padding: "8px 32px",
                      }}
                      type="submit"
                    />
                  </Box>
                </form>
              );
            }}
          </Formik>
        </Box>
      </StepMainContent>
      <Footer />
    </>
  );
};

export default RegisterStep;

import { Box } from "@mui/material";
import ValueWithLabel from "../../ui/text/ValueWithLabel";
import { useState, useCallback } from "react";
import InlineEditableControls from "../../ui/edit/common/InlineEditableControls";
import {
  FragmentType,
  getFragmentData,
  graphql,
} from "../../../api/__generated__";
import { useMutation, useQuery } from "@apollo/client";
import SingleSelect from "../../ui/form/select/SingleSelect";

interface MutualitySelectProps {
  human: FragmentType<typeof humanMutualityFragment>;
  label: string;
  placeholder?: string;
}

const mutualitySelectMutation = graphql(`
  mutation MutualitySelect($mutualityId: String!) {
    updateHumanMutuality(mutualityId: $mutualityId) {
      id
      mutuality {
        id
        title
      }
    }
  }
`);

const mutualitiesQuery = graphql(`
  query SelectMutualites {
    mutualities {
      id
      title
    }
  }
`);

const humanMutualityFragment = graphql(`
  fragment HumanMutuality on Human {
    id
    mutuality {
      id
      title
    }
  }
`);

const MutualitySelect = ({
  label,
  placeholder,
  human,
}: MutualitySelectProps) => {
  const { data: mutualitiesData, loading } = useQuery(mutualitiesQuery);
  const [updateMutuality] = useMutation(mutualitySelectMutation);
  const humanData = getFragmentData(humanMutualityFragment, human);

  const formatMutuality = useCallback(
    (mutuality) =>
      mutuality ? { label: mutuality.title, value: mutuality.id } : undefined,
    [],
  );

  const [mutuality, setMutuality] = useState(
    formatMutuality(humanData.mutuality),
  );

  if (loading || !mutualitiesData) return null;

  const options = mutualitiesData.mutualities.map((mut) => ({
    label: mut.title,
    value: mut.id,
  }));

  return (
    <Box mt={3}>
      <InlineEditableControls
        edit={
          <SingleSelect
            onChange={(value) => setMutuality(value)}
            options={options}
            placeholder={placeholder}
            value={mutuality}
          />
        }
        editable={true}
        label={label}
        onDiscard={() => {
          setMutuality(formatMutuality(humanData.mutuality));

          return true;
        }}
        onSave={async () => {
          if (!mutuality) {
            return false;
          }

          await updateMutuality({
            variables: { mutualityId: mutuality.value },
          });

          return true;
        }}
        read={
          <ValueWithLabel label="" large={false}>
            {humanData.mutuality?.title}
          </ValueWithLabel>
        }
      />
    </Box>
  );
};

export default MutualitySelect;

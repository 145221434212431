import { Box } from "@mui/material";
import { ICallContext } from "../../../ui/call/context/Call";
import CallContext from "../../../ui/call/context/CallContext";
import { useContext } from "react";
import { graphql } from "../../../../api/__generated__";
import ProfessionalScheduleConsultation from "../../../professionals/ProfessionalRequestConsultation";
import { ChimeVideoCall } from "../../../ui/call/ChimeVideoCall.types";
import { useQuery } from "@apollo/client";
import LoadingPage from "../../../layout/LoadingPage";

const requestConsultationHumanQuery = graphql(`
  query RequestConsultationHuman($id: String!) {
    consultation(uuid: $id) {
      human {
        id
        ...RequestConsultationHumanInfo
      }
      id
      professional {
        id
      }
    }
  }
`);

interface ConsultationBookerProps {
  consultationId: string;
}

export default function ConsultationBooker({
  consultationId,
}: ConsultationBookerProps) {
  const { setPaneToPresent } = useContext<ICallContext>(CallContext);
  const { data } = useQuery(requestConsultationHumanQuery, {
    variables: {
      id: consultationId,
    },
  });

  if (!data?.consultation?.human || !data.consultation.professional)
    return <LoadingPage isTransparant />;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {
        <ProfessionalScheduleConsultation
          humanData={data.consultation.human}
          onCancel={() => {
            setPaneToPresent(ChimeVideoCall.SidePaneProfessional.menuPane);
          }}
          onSchedule={() => {
            setPaneToPresent(ChimeVideoCall.SidePaneProfessional.menuPane);
          }}
          professionalId={data.consultation.professional.id}
        />
      }
    </Box>
  );
}

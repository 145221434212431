import React from "react";
import { Box, SxProps, Theme } from "@mui/material";
import { SingleWave } from "./SingleWave";

interface WaveBoxProps {
  background: string;
  bottomWave?: boolean;
  children?: React.ReactNode;
  fillBackground?: boolean;
  id?: string;
  sx?: SxProps<Theme>;
  topWave?: boolean;
  waveHeight?: string;
}

export const WaveBox = ({
  children,
  background,
  topWave = true,
  bottomWave = true,
  waveHeight,
  fillBackground = false,
  sx,
  id,
}: WaveBoxProps) => {
  return (
    <Box
      id={id}
      sx={[
        {
          position: "relative",
          width: "100%",
          zIndex: 1,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {topWave && (
          <SingleWave waveColor={background} waveHeight={waveHeight} />
        )}
        <Box
          sx={{
            background: fillBackground ? background : undefined,
            width: "100%",
          }}
        >
          {children}
        </Box>
        <Box sx={{ background, height: "100%" }} />
        {bottomWave && (
          <SingleWave
            reverseWave
            waveColor={background}
            waveHeight={waveHeight}
          />
        )}
      </Box>
    </Box>
  );
};

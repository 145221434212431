export const shared = {
  REACT_APP_BLOOMUP_FEE_FOR_B2C_CONSULT: 12,
  REACT_APP_GRAPHQL: "/api",
  REACT_APP_I18N_APP_KEY: "mSXug9LBWQwh0wqBy8ufWQ",
  REACT_APP_I18N_VERSION: 297,
  REACT_APP_LANDING_PAGE_SALT: "PLVAs!er2kZhyMNnWA",
  REACT_APP_PUSHER_APP_KEY: "05cd6ed22dd5c572e73d",
  REACT_APP_PUSHER_AUTH: "/pusher/auth",
  REACT_APP_PUSHER_CLUSTER: "eu",
  REACT_APP_SENTRY_DSN:
    "https://a299d1c7833043cc8b8014f67ec5c7ee@sentry.bloomup.org/7",
  REACT_APP_SENTRY_SAMPLE_RATE: 1,
};

export default shared;

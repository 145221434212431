import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { memo, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { InfoOutlined } from "@mui/icons-material";
import {
  FragmentType,
  getFragmentData,
  graphql,
} from "../../../api/__generated__";
import useSelectTranslation from "../../../api/languages/useSelectTranslation";
import { UserRoles } from "../../../data-model/types/User";
import Consultation, {
  ConsultationTypes,
} from "../../../data-model/types/consultation/Consultation";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import LoadingPage from "../../layout/LoadingPage";
import AuthContext from "../../providers/auth/AuthContext";
import { IAuthContext } from "../../providers/auth/auth";
import Label from "../../ui/form/FormLabel";
import TextField from "../../ui/form/TextField";
import ValueWithLabel from "../../ui/text/ValueWithLabel";
import ConsultationDateSelection from "../ConsultationDateSelection";
import ConsultationPriceSelection from "../ConsultationPriceSelection";
import ConsultationStatus from "../../../data-model/types/consultation/ConsultationStatus";
import { OrgProfileDialog } from "../../organizations/OrgProfileDialog";
import ConsultationCancellation from "./ConsultationCancellation";
import ReimbursementInfo from "./ReimbursementInfo";

const PREFIX = "ConsultationInfo";

const classes = {
  infoItem: `${PREFIX}-infoItem`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(() => ({
  [`& .${classes.infoItem}`]: {
    marginBottom: 15,
  },
}));

const consultationFragment = graphql(`
  fragment ConsultationInfoConsultation on Consultation {
    cashTransaction {
      id
      status
    }
    creditTransactions {
      id
    }
    human {
      creditBalance {
        total
      }
      id
      organization {
        id
        name {
          ...useSelectTranslationStringInput
        }
        ...OrgProfileDialogOrganization
      }
      user {
        email
        firstName
        lastName
        phone
      }
    }
    id
    message {
      body
      id
    }
    professional {
      id
      user {
        email
        firstName
        lastName
      }
    }
    ...ReimbursementInfoConsultation
    status
    type
  }
`);

const ConsultationInfo = ({
  consultation,
  graphConsultation,
  isGoingToCancel,
  isEditing,
  formState,
}: {
  consultation: Consultation;
  formState: any;
  graphConsultation: FragmentType<typeof consultationFragment>;
  isEditing: boolean;
  isGoingToCancel: boolean;
  updateConsultationStatus: (uuid: string, status: ConsultationStatus) => void;
}) => {
  const selectTranslation = useSelectTranslation();
  const consultationData = getFragmentData(
    consultationFragment,
    graphConsultation,
  );

  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Partners,
    BloomUpNamespacesEnum.Payments,
  ]);

  const [orgDialogOpen, setOrgDialogOpen] = useState(false);

  const { currentUser } = useContext<IAuthContext>(AuthContext);

  const cashTransaction = consultationData.cashTransaction;
  const creditTransactions = consultationData.creditTransactions;

  const message = consultationData.message?.body;

  const profUser = consultationData.professional?.user;
  const profEmail = profUser?.email;

  const humanUser = consultationData.human?.user;
  const clientEmail = humanUser?.email;
  const clientPhone = humanUser?.phone;
  const creditsLeft = consultationData.human?.creditBalance?.total;

  const showPriceInfo: boolean =
    consultationData.type !== ConsultationTypes.INTRO &&
    (!!cashTransaction || creditTransactions.length > 0);

  if (!currentUser || !consultationData) return <LoadingPage />;

  const organization = consultationData.human?.organization;

  const organizationName = selectTranslation(organization?.name);

  const handleCloseOrgDialog = () => {
    setOrgDialogOpen(false);
  };

  return (
    <Root>
      {currentUser.isProfessional() && organizationName && (
        <Box className={classes.infoItem}>
          <ValueWithLabel
            label={translate("common:partner")}
            onClick={() => setOrgDialogOpen(true)}
          >
            {organizationName}
            <InfoOutlined color="action" sx={{ marginLeft: "4px" }} />
          </ValueWithLabel>
          {organization && (
            <OrgProfileDialog
              onClose={handleCloseOrgDialog}
              open={orgDialogOpen}
              organization={organization}
            />
          )}
        </Box>
      )}
      {currentUser.isProfessional() && (
        <Box className={classes.infoItem}>
          <ValueWithLabel label={translate("common:consultation.with")}>
            {humanUser?.firstName} {humanUser?.lastName} -{" "}
            <a href={`mailto:${clientEmail}`}>{clientEmail}</a> -{" "}
            <a href={`tel:${clientPhone}`}>{clientPhone}</a>
          </ValueWithLabel>
        </Box>
      )}
      {currentUser.getRole() !== UserRoles.PROFESSIONAL && (
        <Box className={classes.infoItem}>
          <ValueWithLabel label={translate("common:consultation.professional")}>
            {profUser?.firstName} {profUser?.lastName} -{" "}
            <a href={`mailto:${profEmail}`}>{profEmail}</a>
          </ValueWithLabel>
        </Box>
      )}
      <Box className={classes.infoItem}>
        <ValueWithLabel label={translate("common:consultation_type.label")}>
          {translate(`common:consultation_type.${consultationData.type}`)}
        </ValueWithLabel>
      </Box>
      <Box className={classes.infoItem}>
        <ValueWithLabel label={translate("common:status.label")}>
          {translate(`common:consultation_status.${consultationData.status}`, {
            context: currentUser.isProfessional() ? "FOR_PROFESSIONAL" : "",
          })}
        </ValueWithLabel>
      </Box>
      {!isEditing && message && (
        <Box className={classes.infoItem}>
          <ValueWithLabel label={translate("common:consultation.message")}>
            {message}
          </ValueWithLabel>
        </Box>
      )}
      <Box className={classes.infoItem}>
        <ConsultationDateSelection
          consultation={consultation}
          formState={formState}
          isEditing={isEditing}
        />
      </Box>
      {showPriceInfo && (
        <>
          {" "}
          <Box className={classes.infoItem}>
            <ConsultationPriceSelection
              canPayWithCredits={consultation.getClient().canPayWithCredits}
              consultation={consultation}
              creditsLeft={creditsLeft}
              formState={formState}
              isEditing={isEditing && currentUser.isProfessional()}
            />
          </Box>
          {cashTransaction && (
            <Box className={classes.infoItem}>
              <ValueWithLabel
                label={translate("payments:payment.status.text", {
                  context: currentUser.isProfessional() ? "professional" : "",
                })}
              >
                {cashTransaction
                  ? translate(
                      `payments:payment.status.${cashTransaction.status.toLowerCase()}`,
                      "Unknown",
                    )
                  : translate("payments:payment.none")}
              </ValueWithLabel>
            </Box>
          )}
          {!consultation.getStatus().isCancelled() &&
            consultationData.type === "APPOINTMENT" &&
            !currentUser.isProfessional() && (
              <Box className={classes.infoItem}>
                <ReimbursementInfo consultation={consultationData} />
              </Box>
            )}
        </>
      )}
      <Box>
        {isEditing && (
          <Box>
            <Label label={translate("common:consultation.cancel.comment")} />
            <TextField
              onChange={() => {}}
              {...formState.getInputProps("message")}
              autoFocus
              required={true}
              value={
                formState.getValue("message")
                  ? formState.getValue("message").body
                  : ""
              }
            />
          </Box>
        )}

        <ConsultationCancellation
          editingMessage={isGoingToCancel}
          formState={formState}
        />
      </Box>
    </Root>
  );
};

export default memo(ConsultationInfo);

import { graphql } from "../__generated__";

export const QUERY_CLIENTS_FOR_PROFESSIONAL = graphql(`
  query clientsForProfessional {
    clientsForProfessional {
      edges {
        node {
          ...ClientForProfessional
          ...RestrictedClientForProfessional
        }
      }
    }
  }
`);

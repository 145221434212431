import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { memo } from "react";
import ConsultationStatus from "../../../data-model/types/consultation/ConsultationStatus";
import Consultation from "../../../data-model/types/consultation/Consultation";
import ConsultationInfo from "./ConsultationInfo";
import ConsultationCalendarLinks from "./ConsultationCalendarLinks";
import ConsultationActions from "./ConsultationActions";
import {
  FragmentType,
  getFragmentData,
  graphql,
} from "../../../api/__generated__";

const PREFIX = "ConsultationDetail";

const classes = {
  root: `${PREFIX}-root`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    "& .status-CANCELLED_BY_HUMAN P.value": {
      color: theme.palette.red.main,
    },
    "& .status-CANCELLED_BY_PROFESSIONAL P.value": {
      color: theme.palette.red.main,
    },
  },
}));

const consultationFragment = graphql(`
  fragment ConsultationDetailConsultation on Consultation {
    ...ConsultationInfoConsultation
    id
  }
`);

interface ConsultationDetail {
  cancelConsultation: () => void;
  cancelling: boolean;
  confirmConsultation: () => void;
  confirming: boolean;
  consultation: Consultation;
  formState: any;
  graphConsultation?: FragmentType<typeof consultationFragment>;
  isEditing: boolean;
  isGoingToCancel: boolean;
  isProfessional: boolean;
  redirectToPayment: () => void;
  setEditing: (val: boolean) => void;
  setIsGoingToCancel: (val: boolean) => void;
  startCall: () => void;
  updateConsultation: () => void;
  updateConsultationStatus: (uuid: string, status: ConsultationStatus) => void;
}

const ConsultationDetail = ({
  consultation,
  graphConsultation,
  formState,
  isEditing,
  isGoingToCancel,
  startCall,
  setEditing,
  setIsGoingToCancel,
  confirmConsultation,
  confirming,
  updateConsultation,
  updateConsultationStatus,
  cancelConsultation,
  cancelling,
  redirectToPayment,
}: ConsultationDetail) => {
  const consultationData = getFragmentData(
    consultationFragment,
    graphConsultation,
  );

  return (
    <StyledBox className={classes.root}>
      {consultationData && (
        <ConsultationInfo
          consultation={consultation}
          formState={formState}
          graphConsultation={consultationData}
          isEditing={isEditing}
          isGoingToCancel={isGoingToCancel}
          updateConsultationStatus={updateConsultationStatus}
        />
      )}
      <ConsultationActions
        cancelConsultation={cancelConsultation}
        cancelling={cancelling}
        confirmConsultation={confirmConsultation}
        confirming={confirming}
        consultation={consultation}
        isEditing={isEditing}
        isGoingToCancel={isGoingToCancel}
        redirectToPayment={redirectToPayment}
        setIsEditing={setEditing}
        setIsGoingToCancel={setIsGoingToCancel}
        startCall={startCall}
        updateConsultation={updateConsultation}
      />
      {!consultation.getStatus().isCancelled() &&
        !consultation.isOver() &&
        !isEditing &&
        !isGoingToCancel && (
          <ConsultationCalendarLinks consultation={consultation} />
        )}
    </StyledBox>
  );
};

export default memo(ConsultationDetail);

import { createContext } from "react";
import { defaultFormStateValues } from "../../hooks/useFormState";
import { IPaymentContext } from "./payment";

const defaultPaymentValues: IPaymentContext = {
  checked: false,
  formState: defaultFormStateValues,
  pay: () => {},
  setChecked: () => {},
};

const PaymentContext = createContext<IPaymentContext>(defaultPaymentValues);

export default PaymentContext;

/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import useLazyFetchConsultationByUuid from "../../../api/consultations/hooks/useLazyFetchConsultationByUuid";
import usePayForConsultation from "../../../api/payments/hooks/usePayForConsultation";
import usePaymentFormState from "../usePaymentFormState";
import useUpdateHumanMutation from "../../../api/humans/hooks/useUpdateHumanMutation";
import HealthInsurance from "../../../data-model/types/reimbursement/HealthInsurance";
import CashTransaction from "../../../data-model/types/CashTransaction";
import PaymentContext from "./PaymentContext";

export default function PaymentContextProvider({ children }) {
  const { fetchConsultation, consultation } = useLazyFetchConsultationByUuid();

  const { updateHuman } = useUpdateHumanMutation();
  const [checked, setChecked] = useState<boolean>(false);

  const formState = usePaymentFormState();
  const [paymentStarted, setPaymentStarted] = useState<boolean>(false);

  const {
    payForConsultation,
    loading: paymentOngoing,
    result,
  } = usePayForConsultation();

  useEffect(() => {
    if (!paymentOngoing && result) {
      window.location.replace(result);
    }
  }, [paymentOngoing, result]);

  useEffect(() => {
    if (!consultation) return;

    const cashTransaction: CashTransaction | undefined =
      consultation.getCashTransaction();

    if (cashTransaction && !paymentOngoing && !paymentStarted) {
      setPaymentStarted(true);
      payForConsultation(cashTransaction.getID());
    }
  }, [consultation, paymentOngoing]);

  const pay = async (uuid: string) => {
    await updateHuman({
      healthInsurance: formState.getValue(HealthInsurance.getPath()),
    });

    fetchConsultation(uuid);
  };

  // if (/*|| loading*/)
  //     return <LoadingPage full={false} />;

  return (
    <PaymentContext.Provider
      value={{
        checked,
        formState,
        pay,
        setChecked,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
}

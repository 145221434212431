import { Avatar, Box, Typography, useTheme } from "@mui/material";
import useSelectTranslation from "../../../../api/languages/useSelectTranslation";
import {
  differenceInCalendarDays,
  differenceInYears,
  format,
  formatRelative,
} from "date-fns";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Chip from "./Chip";
import {
  FragmentType,
  getFragmentData,
  graphql,
} from "../../../../api/__generated__";
import ChooseConsultation from "./ChooseConsultation";
import { IntroDetails } from "../Match";
import { useState } from "react";
import Button from "../../../ui/buttons/Button";
import TodayIcon from "@mui/icons-material/Today";
import ConsultationRules from "./ConsultationRules";
import { getDateFnsLocale } from "../../../language/languagesUtil";
import CostSentence from "./CostSentence";

const profCardMatchedHumanFragment = graphql(`
  fragment ProfCardMatchedHuman on MatchedHuman {
    human {
      ...CostSentenceHuman
      id
    }
    mutuality {
      id
      title
    }
    organization {
      ...CostSentenceOrganization
      id
    }
    subtopic {
      id
    }
    treatment
  }
`);

const profCardProfFragment = graphql(`
  fragment ProfCardProf on Professional {
    availabilityV2(amountOfDaysInTheFuture: 31, consultationType: INTRO)
    avatar {
      url
    }
    coachingEnabled
    consultationLanguages
    ...CostSentenceProfessional
    experienceSince
    gender
    id
    isOnline
    primarySubtopics {
      id
      titleTranslationKey
    }
    profileDescription {
      ...useSelectTranslationStringInput
    }
    secondarySubtopics {
      id
      titleTranslationKey
    }
    type
    user {
      firstName
      id
      lastName
    }
    website
  }
`);

interface ProfCardProps {
  bookIntroConsult: (introDetails: IntroDetails) => Promise<void>;
  matchedHuman?: FragmentType<typeof profCardMatchedHumanFragment>;
  prof: FragmentType<typeof profCardProfFragment>;
  spotlight?: boolean;
}

const ProfCard = ({
  bookIntroConsult,
  matchedHuman,
  prof,
  spotlight,
}: ProfCardProps) => {
  const selectTranslation = useSelectTranslation();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const dateFnsLocale = getDateFnsLocale(i18n.language);

  const professionalData = getFragmentData(profCardProfFragment, prof);

  const {
    availabilityV2,
    coachingEnabled,
    consultationLanguages,
    //isOnline,
    primarySubtopics,
    profileDescription,
    secondarySubtopics,
    user,
    avatar,
    id: profId,
    experienceSince,
    gender,
    type,
    website,
  } = professionalData;

  const matchedHumanData = getFragmentData(
    profCardMatchedHumanFragment,
    matchedHuman,
  );

  const organizationData = matchedHumanData?.organization;

  const experienceInYears = experienceSince
    ? differenceInYears(Date.now(), experienceSince)
    : 0;

  const firstDate = availabilityV2[0] ? new Date(availabilityV2[0]) : undefined;
  const [showBookPane, setShowBookPane] = useState(false);
  const subtopics = [...primarySubtopics, ...secondarySubtopics].sort(
    (a, b) => {
      if (a.id === matchedHumanData?.subtopic?.id) {
        return -10;
      } else if (b.id === matchedHumanData?.subtopic?.id) {
        return 10;
      }

      return 0;
    },
  );

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        gap: "60px",
      }}
    >
      <Box
        sx={{
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
          gap: "30px",
          maxWidth: "850px",
          textAlign: "left",
        }}
      >
        <Box
          sx={{
            display: "flex",
            [theme.breakpoints.down(400)]: { flexDirection: "column" },
            gap: "25px",
          }}
        >
          <Avatar
            alt={user.firstName + " " + user.lastName}
            src={avatar?.url ?? undefined}
            sx={{
              border: "1px",
              borderColor: spotlight
                ? theme.palette.green.dark
                : theme.palette.bloom.dark,
              height: "200px",
              width: "200px",
              [theme.breakpoints.down(750)]: {
                height: "120px",
                width: "120px",
              },
              [theme.breakpoints.down(500)]: {
                height: "100px",
                width: "100px",
              },
            }}
          >
            {avatar?.url ? null : user.firstName}
          </Avatar>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{ [theme.breakpoints.down(750)]: { fontSize: "24px" } }}
              variant="h1"
            >
              {user.firstName + " " + user.lastName}
            </Typography>
            {gender && (
              <Typography sx={{ fontSize: "16px" }} variant="caption">
                {t("match:gender." + gender)}
              </Typography>
            )}
            <Typography sx={{ fontSize: "16px" }} variant="caption">
              {t("match:languages") + " "}
              {consultationLanguages.map((lang, index) => {
                return (
                  t("match:languages." + lang) +
                  (index !== consultationLanguages.length - 1 ? ", " : "")
                );
              })}
            </Typography>
            <Typography
              sx={{
                [theme.breakpoints.down(750)]: {
                  display: "none",
                },
              }}
              variant="body1"
            >
              {selectTranslation(profileDescription)}
            </Typography>
            {website && (
              <Typography variant="body1">
                {t("match:chooseProfStep.card.website") + " "}
                <Link target="blank" to={"https://" + website}>
                  {website}
                </Link>
              </Typography>
            )}
          </Box>
        </Box>
        <Typography
          sx={{
            [theme.breakpoints.up(750)]: {
              display: "none",
            },
          }}
          variant="body1"
        >
          {selectTranslation(profileDescription)}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            [theme.breakpoints.up(750)]: { paddingLeft: "20px" },
          }}
        >
          <Typography variant="body1">
            {experienceInYears === 0
              ? t(
                  "match:chooseProfStep.card.no.experience" +
                    (coachingEnabled ? "_coaching" : ""),
                  {
                    firstName: user.firstName,
                    role: t(`common:${type}`),
                  },
                )
              : t(
                  "match:chooseProfStep.card.experience" +
                    (coachingEnabled ? "_coaching" : ""),
                  {
                    experience: experienceInYears,
                    firstName: user.firstName,
                    role: t(`common:${type}`),
                  },
                )}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "5px 10px",
              [theme.breakpoints.up(750)]: { paddingBottom: "20px" },
            }}
          >
            {subtopics.map((topic) => {
              return (
                <Chip
                  key={topic.id}
                  light={topic.id !== matchedHumanData?.subtopic?.id}
                  text={t(topic.titleTranslationKey)}
                />
              );
            })}
          </Box>
          {matchedHumanData?.treatment &&
          matchedHumanData.treatment !== "SELFHELP" ? (
            <CostSentence
              appointmentType={matchedHumanData.treatment}
              human={matchedHumanData.human}
              isMatching
              mutualityName={matchedHumanData.mutuality?.title ?? undefined}
              organization={organizationData}
              professional={professionalData}
            />
          ) : null}
        </Box>

        <Box
          sx={{
            alignSelf: "center",
            display: "inline-grid",
            gap: "14px",
            gridTemplateColumns: "1fr 1fr",
            justifyContent: "center",
            paddingTop: "30px",
            [theme.breakpoints.down(700)]: {
              gridTemplateColumns: "1fr",
            },
          }}
        >
          {/* {isOnline ? (     ---> ENG-1300
          <Button
            bloomColor="green"
            fullyColored
            icon={<VideocamIcon />}
            label={t("match:chooseProfstep.card.chooseConsultation.startNow")}
            size="medium"
            sx={{ fontSize: "16px",  [theme.breakpoints.up(400)]: { textWrap: "nowrap" }, }}
          />
        ) : ( */}
          <Button
            fullyColored
            icon={<TodayIcon />}
            label={t("match:chooseProfstep.card.nextConsult.button", {
              time: firstDate
                ? differenceInCalendarDays(firstDate, new Date()) < 7
                  ? formatRelative(firstDate, new Date(), {
                      locale: dateFnsLocale,
                    })
                  : format(firstDate, "dd MMM HH:mm", { locale: dateFnsLocale })
                : undefined,
            })}
            onClick={() => {
              bookIntroConsult({
                professionalId: profId,
                startTime: new Date(availabilityV2[0]),
              });
            }}
            size="medium"
            sx={{
              textWrap: "nowrap",
              width: "100%",
              [theme.breakpoints.down(400)]: {
                // A way to work with the ugly button library :p
                height: "unset !important",
                padding: "6px 12px !important",
                textWrap: "wrap",
              },
            }}
          />
          {/* )} */}
          <Button
            icon={<TodayIcon />}
            label={t("match:chooseProfstep.card.chooseConsultation.chooseTime")}
            onClick={() => {
              setShowBookPane(true);
            }}
            size="medium"
            sx={{
              textWrap: "nowrap",
              [theme.breakpoints.down(400)]: {
                height: "unset  !important",
                padding: "6px 12px !important",
                textWrap: "wrap",
              },
              width: "100%",
            }}
            variant="outlinedV2"
          />
        </Box>
      </Box>
      {showBookPane && (
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ChooseConsultation
            availability={availabilityV2}
            bookIntroConsult={bookIntroConsult}
            profId={profId}
          />
          <ConsultationRules />
        </Box>
      )}
    </Box>
  );
};

export default ProfCard;

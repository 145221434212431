import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import OnboardingContainer from "../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../layout/wizard/OnboardingStepContainer";
import TextField from "../ui/form/TextField";
import Label from "../ui/form/FormLabel";
import Button from "../ui/buttons/Button";
import ListItemWithIcon from "../ui/lists/ListItemWithIcon";
import FAQAccordionItem from "../ui/FAQAccordionItem";
import FAQAccordion from "../ui/FAQAccordion";
import CircleWithNumber from "../ui/lists/CircleWithNumber";
import { graphql } from "../../api/__generated__";
import { useMutation, useQuery } from "@apollo/client";
import { RouteNames } from "../routes/routeNames";
import { Formik } from "formik";
import ToastContext from "../providers/toast/ToastContext";
import LoadingPage from "../layout/LoadingPage";

const humanQuery = graphql(`
  query ReimbursementPageHuman {
    currentUser {
      human {
        id
        mutuality {
          id
          title
        }
      }
      id
    }
  }
`);

const updateHumanMutualityMutation = graphql(`
  mutation UpdateHumanMutualityOnReimbursementInfo($mutualityId: String!) {
    updateHumanMutuality(mutualityId: $mutualityId) {
      id
      mutuality {
        id
        title
      }
    }
  }
`);

const requestReimbursementMutation = graphql(`
  mutation RequestReimbursement($input: ReimbursementInput!) {
    requestReimbursement(input: $input) {
      id
    }
  }
`);

const padZero = (num: string, size: number) => {
  while (num.length < size) num = `0${num}`;

  return num;
};

const verifyCheckSumNationalId = (nationalId: string) => {
  if (
    !/^[0-9]{2}[.\- ]{0,1}[0-9]{2}[.\- ]{0,1}[0-9]{2}[.\- ]{0,1}[0-9]{3}[.\- ]{0,1}[0-9]{2}$/.test(
      nationalId,
    )
  ) {
    return false;
  }

  const cleanedNumber = nationalId.replace(/\D/g, "");
  const birthYear = parseInt(cleanedNumber.toString().substring(0, 2));

  const currYear = parseInt(String(new Date().getFullYear()).slice(-2));

  const birthDate = padZero(cleanedNumber.substring(0, 6), 6);

  const serialNumber = padZero(cleanedNumber.substring(6, 9), 3);

  const rest =
    parseInt(`${birthYear <= currYear ? 2 : ""}${birthDate}${serialNumber}`) %
    97;

  return 97 - rest === parseInt(String(cleanedNumber).slice(-2));
};

export default function ReimbursementPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uuid } = useParams();
  const { setToast } = useContext(ToastContext);
  const [searchParams] = useSearchParams();

  const [updateHumanMutuality] = useMutation(updateHumanMutualityMutation, {
    onCompleted: (data) => {
      const mutualityTitle = data.updateHumanMutuality?.mutuality?.title;

      if (!["CM", "Helan"].includes(mutualityTitle ?? "") || !uuid) {
        navigate(RouteNames.Home.Consultations.path);
        setToast({
          message: "ERROR",
          severity: "error",
        });
      }
    },
  });

  const { data: humanData } = useQuery(humanQuery, {
    onCompleted: (data) => {
      const mutualityTitle = data.currentUser?.human?.mutuality?.title;
      const mutualityIdFromUrl = searchParams.get("linkToMutuality");

      if (!mutualityTitle && mutualityIdFromUrl) {
        updateHumanMutuality({
          variables: { mutualityId: mutualityIdFromUrl },
        });
      } else if (!["CM", "Helan"].includes(mutualityTitle ?? "") || !uuid) {
        navigate(RouteNames.Home.Consultations.path);
        setToast({
          message: "ERROR",
          severity: "error",
        });
      }
    },
  });

  const [requestReimbursement] = useMutation(requestReimbursementMutation);

  const mutualityTitle = humanData?.currentUser?.human?.mutuality?.title;

  return (
    <OnboardingContainer>
      <Formik
        initialValues={{ nationalId: "" }}
        onSubmit={(values) => {
          if (uuid) {
            // already checked in oncomplete of humanQuery
            requestReimbursement({
              variables: {
                input: {
                  consultationUuid: uuid,
                  nationalId: values.nationalId,
                },
              },
            });

            navigate(
              `${RouteNames.Home.Consultations.ShowConsultation.path.replace(
                ":uuid",
                uuid,
              )}?reimbursementState=requested`,
            );
          }
        }}
        validate={(values) => {
          if (!verifyCheckSumNationalId(values.nationalId)) {
            return {
              nationalId: t("validation:national.id.correctformat"),
            };
          } else {
            return {};
          }
        }}
        validateOnChange={false}
      >
        {({
          handleSubmit,
          errors,
          handleBlur,
          values,
          handleChange,
          submitForm,
          touched,
        }) => {
          return (
            <OnboardingStepContainer
              fullContent={true}
              fullCopyright
              headline={t("payments:payment")}
              onSubmit={handleSubmit}
              showLanguageSelector={false}
            >
              {["CM", "Helan"].includes(mutualityTitle ?? "") ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <Box sx={(theme) => ({ flex: 1, margin: theme.spacing(5) })}>
                    <Typography
                      sx={(theme) => ({
                        flex: 1,
                        marginBottom: theme.spacing(4),
                      })}
                      variant="h2"
                    >
                      {t("payments:health.insurance.signup.title1", {
                        mutualityTitle,
                      })}
                    </Typography>

                    <Typography
                      sx={(theme) => ({
                        flex: 1,
                        marginBottom: theme.spacing(2),
                      })}
                    >
                      {t("payments:health.insurance.signup.description1", {
                        mutualityTitle,
                      })}
                    </Typography>
                    <Box
                      sx={(theme) => ({
                        flex: 1,
                        marginBottom: theme.spacing(2),
                      })}
                    >
                      {mutualityTitle === "CM" ? (
                        <ListItemWithIcon
                          text={t(
                            "payments:health.insurance.signup.list.item1",
                          )}
                        />
                      ) : null}

                      <ListItemWithIcon
                        text={t("payments:health.insurance.signup.list.item2")}
                      />
                      <ListItemWithIcon
                        text={t("payments:health.insurance.signup.list.item3")}
                      />
                    </Box>

                    <Typography
                      sx={(theme) => ({
                        flex: 1,
                        marginBottom: theme.spacing(4),
                      })}
                    >
                      {mutualityTitle === "CM"
                        ? t("payments:health.insurance.signup.description2")
                        : t(
                            "payments:health.insurance.signup.description2_helan",
                          )}
                    </Typography>
                    <Typography
                      sx={(theme) => ({
                        flex: 1,
                        marginBottom: theme.spacing(4),
                      })}
                      variant="h2"
                    >
                      {t("payments:health.insurance.signup.title2")}
                    </Typography>
                    <Box>
                      <FAQAccordion>
                        {[
                          {
                            answer: t(
                              "payments:reimbursement.page.faq.nomorereimbursement.answer",
                              { mutualityTitle },
                            ),
                            question: t(
                              "payments:reimbursement.page.faq.nomorereimbursement.question",
                            ),
                          },
                          {
                            answer: t(
                              "payments:reimbursement.page.faq.cancellation.answer",
                              { mutualityTitle },
                            ),
                            question: t(
                              "payments:reimbursement.page.faq.cancellation.question",
                            ),
                          },
                          {
                            answer: t(
                              "payments:reimbursement.page.faq.nationalid.answer",
                            ),
                            question: t(
                              "payments:reimbursement.page.faq.nationalid.question",
                            ),
                          },
                          {
                            answer: t(
                              "payments:reimbursement.page.faq.datasharing.answer",
                              { mutualityTitle },
                            ),
                            question: t(
                              "payments:reimbursement.page.faq.datasharing.question",
                              { mutualityTitle },
                            ),
                          },
                        ].map((question) => {
                          return (
                            <FAQAccordionItem
                              answer={question.answer}
                              key={question.question}
                              question={question.question}
                            />
                          );
                        })}
                      </FAQAccordion>
                    </Box>
                  </Box>

                  <Box sx={(theme) => ({ flex: 1, margin: theme.spacing(5) })}>
                    <Typography variant="h2">
                      {t("payments:health.insurance.signup.title3")}
                    </Typography>
                    <Box
                      sx={(theme) => ({
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: theme.spacing(4),
                        marginTop: theme.spacing(4),
                      })}
                    >
                      <CircleWithNumber number={1} />
                      <Typography sx={{ marginLeft: "15px" }}>
                        {t("payments:health.insurance.signup.step1", {
                          mutualityTitle,
                        })}
                      </Typography>
                    </Box>
                    <Box
                      sx={(theme) => ({
                        backgroundColor: theme.palette.bloom.light,
                        borderRadius: "14px",
                        display: "flex",
                        flexDirection: "column",
                        padding: theme.spacing(5),
                        position: "relative",
                      })}
                    >
                      <Label
                        label={t("common:nationalnumber")}
                        style={{
                          marginBottom: 10,
                          marginTop: 0,
                        }}
                      />
                      <TextField
                        errorText={errors.nationalId}
                        hasError={!!errors.nationalId}
                        id="nationalId"
                        name="nationalId"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onKeyDown={(e) =>
                          e.key === "Enter" ? submitForm() : null
                        }
                        placeholder={t(
                          "payments:health.insurance.signup.textfield.nationalnumber.placeholder",
                        )}
                        value={values.nationalId}
                        withFormik
                      />
                    </Box>

                    <Box
                      sx={(theme) => ({
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: theme.spacing(4),
                        marginTop: theme.spacing(4),
                      })}
                    >
                      <CircleWithNumber number={2} />
                      <Typography sx={{ marginLeft: "15px" }}>
                        {t("payments:health.insurance.signup.step2", {
                          mutualityTitle,
                        })}
                      </Typography>
                    </Box>
                    <Box
                      sx={(theme) => ({
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: theme.spacing(4),
                        marginTop: theme.spacing(4),
                      })}
                    >
                      <CircleWithNumber number={3} />
                      <Typography sx={{ marginLeft: "15px" }}>
                        {t("payments:health.insurance.signup.step3", {
                          mutualityTitle,
                        })}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box
                        sx={(theme) => ({
                          display: "flex",
                          flex: 1,
                          justifyContent: "center",
                          marginBottom: theme.spacing(4),
                        })}
                      >
                        <Button
                          disabled={!touched.nationalId || !!errors.nationalId}
                          label={t(
                            "payments:health.insurance.signup.button.askfor",
                          )}
                          type="submit"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <LoadingPage />
              )}
            </OnboardingStepContainer>
          );
        }}
      </Formik>
    </OnboardingContainer>
  );
}
